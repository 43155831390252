import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useCartStore = create(
  persist(
    (set, get) => ({
      // State
      items: [],

      // Actions
      setItems: (items) => {
        // Ensure each item has a quantity of at least 1
        const itemsWithQuantity = items.map(item => ({
          ...item,
          quantity: item.quantity || 1
        }))
        set({ items: itemsWithQuantity })
      },

      addItem: (item, quantity = 1) => {
        const { items } = get()
        const existingItem = items.find(i => i.id === item.id)

        if (existingItem) {
          set({
            items: items.map(i =>
              i.id === item.id
                ? { ...i, quantity: i.quantity + quantity }
                : i
            )
          })
        } else {
          set({
            items: [...items, { ...item, quantity }]
          })
        }
      },

      updateItem: (itemId, data) => {
        const { items } = get()
        set({
          items: items.map(item =>
            item.id === itemId ? { ...item, ...data } : item
          )
        })
      },

      updateItemQuantity: (itemId, quantity) => {
        const { items } = get()
        set({
          items: items.map(item =>
            item.id === itemId ? { ...item, quantity } : item
          )
        })
      },

      removeItem: (itemId) => {
        const { items } = get()
        set({
          items: items.filter(item => item.id !== itemId)
        })
      },

      emptyCart: () => {
        set({ items: [] })
      },

      clearCart: () => {
        // Clear cart and remove from localStorage
        set({ items: [] })
        localStorage.removeItem('sensatia-cart')
      },

      // Selectors
      getItem: (itemId) => {
        const { items } = get()
        return items.find(item => item.id === itemId)
      },

      isEmpty: () => {
        const { items } = get()
        return items.length === 0
      },

      totalItems: () => {
        const { items } = get()
        return items.reduce((total, item) => total + item.quantity, 0)
      },

      cartTotal: () => {
        const { items } = get()
        return items.reduce((total, item) => total + (item.price * item.quantity), 0)
      }
    }),
    {
      name: 'sensatia-cart',
      getStorage: () => localStorage
    }
  )
)

export default useCartStore 