import React, { createContext, useReducer } from 'react';
import { useCart } from '../hooks/useCart';
import { useCookies } from 'react-cookie';

import { supportedCurrencies, langCurrency } from './../../data/languages'
import { canUseDOM } from './../utils/cart-helpers'

const CheckoutContext = createContext();

function reducer(state, { payload, type }) {
  switch (type) {
    case 'CHECKOUT_PROCESSING':
      return {
        ...state,
        processing: true,
        error: null,
      };
    case 'CHECKOUT_ERROR':
      return {
        ...state,
        processing: false,
        allowPayment: false,
        shipping: '-',
        gifts: [],
        error: payload.message
      };
    case 'CHECKOUT_SUCCESS':
      return {
        ...state,
        allowPayment: false,
        processing: false,
        shipping: '-',
        shippingOptions: [],
        shippingDiscount: 0,
        isFreeShipping: false,
        gifts: [],
        error: null,
        success: true,
      };
    case 'CHECKOUT_UPDATE_SHIPPING_OPTIONS':
      return {
        ...state,
        shippingOptions: payload.shippingOptions || [],
      }
    case 'CHECKOUT_UPDATE_SHIPPING':
      return {
        ...state,
        shipping: payload.shippingCost || '-',
        shippingDiscount: payload.shippingDiscount || 0,
        isFreeShipping: payload.isFreeShipping || false
      };
    case 'CHECKOUT_UPDATE_MIDTRANS_TOKEN':
      return {
        ...state,
        midtransToken: payload.token,
        midtransSaleId: payload.saleId,
      };
    case 'CHECKOUT_UPDATE_TAX':
      return {
        ...state,
        tax: payload.tax,
        isTaxIncluded: payload.isTaxIncluded
      };
    case 'SET_GIFTS':
      return {
        ...state,
        gifts: payload,
      };
    case 'SET_GIFT_NOT_WANTED':
      return {
        ...state,
        giftNotWanted: payload,
      };
    case 'CHECKOUT_PAYMENT':
      return {
        ...state,
        formValues: payload,
        allowPayment: true,
        processing: false,
        error: null,
      };
    case 'DISABLE_PAYMENT':
      return {
        ...state,
        allowPayment: false,
        success: false,
        processing: false,
        error: null,
        shipping: '-',
        tax: 0,
        discountType: null,
        discount: 0,
      };
    case 'CHECKOUT_UPDATE_CURRENCY':
      return {
        ...state,
        customCurrency: payload.code || state.customCurrency,
        customLocale: payload.locale || state.customLocale,
        customCountryCode: payload.countryCode || state.customCountryCode,
        latitude: payload.latitude || state.latitude,
        longitude: payload.longitude || state.longitude,
        isCurrencySet: payload.isCurrencySet !== undefined ? payload.isCurrencySet : state.isCurrencySet,
        isCurrencyInitializing: payload.isCurrencyInitializing !== undefined ? payload.isCurrencyInitializing : state.isCurrencyInitializing,
        formValues: payload.formValues || state.formValues
      };
    case 'CHECKOUT_SET_INVALID_ITEM':
      return {
        ...state,
        invalidItems: payload
      };
    default:
      throw new Error('Invalid action');
  }
}

function CheckoutProvider({ children }) {
  const { cartTotal, items } = useCart();
  const [cookies, setCookie] = useCookies(['sensatiaCurrency']);
  const { sensatiaCurrency = '' } = cookies;
  
  // Add isSSR flag - set to true to force currency based on URL path
  const isSSR = false; // Set this to true to test URL-based currency

  const [state, dispatch] = useReducer(reducer, {
    allowPayment: false,
    processing: false,
    error: null,
    success: false,
    shipping: '-',
    tax: 0,
    discountType: null,
    discount: 0,
    midtransToken: '',
    midtransSaleId: '',
    customCurrency: sensatiaCurrency || 'USD',
    customLocale: 'en',
    customCountryCode: 'US',
    isCurrencySet: sensatiaCurrency !== '',
    isCurrencyInitializing: false,
    formValues: {},
    isTaxIncluded: false,
    isFreeShippping: false,
    shippingDiscount: 0,
    gifts: [],
    giftNotWanted: false,
    invalidItems: [],
  });

  // Define all functions that use hooks at the top level
  const disablePayment = React.useCallback(() => {
    dispatch({ type: 'DISABLE_PAYMENT' });
  }, []);

  const checkoutError = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_ERROR', payload });
  }, []);

  const setGifts =payload => {
    dispatch({ type: 'SET_GIFTS', payload });
  };

  const setGiftNotWanted = React.useCallback(payload => {
    dispatch({ type: 'SET_GIFT_NOT_WANTED', payload });
  }, []);

  const checkoutPayment = React.useCallback((payload) => {
    dispatch({ type: 'CHECKOUT_PAYMENT', payload });
  }, []);

  const checkoutProcessing = React.useCallback(() => {
    dispatch({ type: 'CHECKOUT_PROCESSING' });
  }, []);

  const checkoutSuccess = React.useCallback(() => {
    dispatch({ type: 'CHECKOUT_SUCCESS' });
  }, []);

  const updateShipping = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_SHIPPING', payload });
  }, []);

  const updateShippingOptions = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_SHIPPING_OPTIONS', payload });
  }, []);

  const updateTax = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_TAX', payload });
  }, []);

  const updateMidtransToken = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_MIDTRANS_TOKEN', payload });
  }, []);

  const setInvalidItems = React.useCallback(payload => {
    dispatch({ type: 'CHECKOUT_SET_INVALID_ITEM', payload });
  }, []);

  const updateCustomCurrency = React.useCallback(({ currency: code = 'USD', locale = 'en', formValues = {} }) => {
    dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { code, locale, formValues } });
  }, []);

  const updateCurrency = React.useCallback(async ({locale = 'en'}) => {
    if (state.isCurrencySet || state.isCurrencyInitializing) {
      return;
    }

    dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { isCurrencyInitializing: true } });

    try {
      if (isSSR || !canUseDOM) {
        // Force currency based on locale/path
        const customCurrency = langCurrency[locale][0]
        dispatch({ 
          type: 'CHECKOUT_UPDATE_CURRENCY', 
          payload: { 
            code: customCurrency, 
            locale, 
            countryCode: 'US',
            isCurrencySet: true,
            isCurrencyInitializing: false 
          } 
        });
      } else {
        const res = await fetch('/.netlify/functions/currency-lookup')
        if (res.ok) {
          const result = await res.json()
          let payload
          const isValidLookup = result && result.locale && result.code && supportedCurrencies.includes(result.code)
          if (isValidLookup) {
            payload = result
          } else {
            const { latitude, longittude, countryCode, continentCode, code } = result
            const isEuropean = continentCode === 'EU'
            const isRussian = code === 'RUB'
            const customCurrency = isEuropean || isRussian ? 'EUR' : "USD"
            payload = { code: customCurrency, locale: 'en', countryCode, latitude, longittude }
          }
          const { code } = payload
          setCookie('sensatiaCurrency', code, { path: '/' });
          dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { ...payload, isCurrencySet: true, isCurrencyInitializing: false } });
        } else {
          console.log('unable to perform currency-lookup. emptycard')
          dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { isCurrencyInitializing: false } });
        }
      }
    } catch (error) {
      console.error('Error initializing currency:', error);
      dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { isCurrencyInitializing: false } });
    }
  }, [isSSR, state.isCurrencySet, state.isCurrencyInitializing, setCookie]);

  const orderTotal = React.useMemo(() => 
    cartTotal + (state.isTaxIncluded || isNaN(state.tax) ? 0 : state.tax) + (!isNaN(state.shipping) ? state.shipping : 0),
    [cartTotal, state.isTaxIncluded, state.tax, state.shipping]
  );

  const value = React.useMemo(() => ({
    ...state,
    orderTotal,
    disablePayment,
    checkoutError,
    setGifts,
    setGiftNotWanted,
    checkoutPayment,
    checkoutProcessing,
    checkoutSuccess,
    updateShipping,
    updateShippingOptions,
    updateTax,
    updateMidtransToken,
    setInvalidItems,
    updateCustomCurrency,
    updateCurrency,
  }), [
    state,
    orderTotal,
    disablePayment,
    checkoutError,
    setGifts,
    setGiftNotWanted,
    checkoutPayment,
    checkoutProcessing,
    checkoutSuccess,
    updateShipping,
    updateShippingOptions,
    updateTax,
    updateMidtransToken,
    setInvalidItems,
    updateCustomCurrency,
    updateCurrency,
  ]);

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}

export { CheckoutProvider, CheckoutContext as default };
