import useCartStore from '../store/cartStore'

export const useCart = () => {
  const store = useCartStore()

  return {
    // State
    items: store.items,
    isEmpty: store.isEmpty(),
    totalItems: store.totalItems(),
    cartTotal: store.cartTotal(),

    // Actions
    setItems: store.setItems,
    addItem: store.addItem,
    updateItem: store.updateItem,
    updateItemQuantity: store.updateItemQuantity,
    removeItem: store.removeItem,
    emptyCart: store.emptyCart,
    clearCart: store.clearCart,

    // Selectors
    getItem: store.getItem
  }
} 